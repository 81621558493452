<template>
  <!-- 订单管理 —— 订单信息 -->
  <div class="box-card">
    <div style="text-align: left">
      <!-- 查询表单 -->
      <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
        <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
          @submit.native.prevent>
          <el-row>
            <el-col :span="24">
              <el-form-item label="停车场：">
                <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable
                  default-first-option placeholder="请选择停车场" @change="onSubmit">
                  <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
                    :value="item.parking_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车牌号：">
                <el-input maxlength="8" placeholder="请输入车牌号" :value="queryForm.plate_number"
                  @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))"
                  @keyup.enter.native="onSubmit"></el-input>
              </el-form-item>
              <el-form-item label="订单号：">
                <el-input maxlength="30" placeholder="请输入订单号" :value="queryForm.order_no"
                  @input="(e) => (queryForm.order_no = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
              </el-form-item>
              <el-form-item label="时间段：">
                <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                  start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item v-if="!isMultiRow">
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isMultiRow">
            <el-col :span="24">
              <el-form-item label="支付方式：">
                <el-select popper-class="my-select" clearable v-model="queryForm.payment_method" placeholder="请选择支付方式"
                  @clear="queryForm.payment_method = null" @change="onSubmit">
                  <el-option v-for="item in getOptions('PaymentMethod')" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单来源：">
                <el-select popper-class="my-select" clearable v-model="queryForm.order_source" placeholder="请选择订单来源"
                  @clear="queryForm.order_source = null" @change="onSubmit">
                  <el-option v-for="item in getOptions('OrderSource')" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="isMultiRow">
                <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </lebo-query>
      <le-card title="退款记录">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
           <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
             <template slot-scope="{row}" slot="plate_number">
              <span>{{ row.plate_number ? row.plate_number : '暂未录入车牌' }}</span>
            </template>
            <template slot-scope="{row}" slot="apply_refund_cost">
              <span>{{ '￥' + row.apply_refund_cost.toFixed(2) }}</span>
            </template>
            <template slot-scope="{row}" slot="payment_method">
              <span>{{
                  row.payment_method === 0 ? '未支付' :
                  getWayName("PaymentMethod", row.payment_method)
                }}</span>
            </template>
            <template slot-scope="{row}" slot="order_source">
              <span>{{
                  getWayName("OrderSource", row.order_source)
                }}</span>
            </template>
            <template slot-scope="{row}" slot="order_status">
              <span :style="row.order_status === 5 ? 'color:#ee0000' : ''">{{ getWayName("OrderStatus",
                  row.order_status) }}</span>
            </template>
            <template slot-scope="{row}" slot="refund_time">
              <span>{{ validDateTime(row.refund_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="find(row)"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="order_no" label="订单号" width="150"></el-table-column>
            <el-table-column prop="plate_number" label="车牌号">
              <template slot-scope="scope">
                <span>{{ scope.row.plate_number ? scope.row.plate_number : '暂未录入车牌' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="parking_name" label="停车场"></el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>{{ '￥' + scope.row.apply_refund_cost.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="支付方式" width="120">
              <template slot-scope="scope">
                <span>{{
                  scope.row.payment_method === 0 ? '未支付' :
                  getWayName("PaymentMethod", scope.row.payment_method)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单来源" width="120">
              <template slot-scope="scope">
                <span>{{
                  getWayName("OrderSource", scope.row.order_source)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <span :style="scope.row.order_status === 5 ? 'color:#ee0000' : ''">{{ getWayName("OrderStatus",
                  scope.row.order_status) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单退款时间" width="180">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.refund_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="find(scope.row)"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" ></el-pagination>
        </div>
      </le-card>
    </div>
    <el-dialog append-to-body title="退款说明" :visible.sync="dialogFormVisible"
      @close="dialogClose" width="35%">
      <el-form :model="queryRefund" label-width="120px" :rules="refundFormRules" ref="refundref">
        <el-form-item label="退款金额：" prop="refundMoney">
          <span>￥{{ queryRefund.refundMoney }}</span>
        </el-form-item>
        <el-form-item label="退款原因：" prop="apply_refund_reason">
          <el-input style="width:90%" v-model="queryRefund.apply_refund_reason" maxlength="150" placeholder="请输入退款原因"
            rows="6" type="textarea" @input="(e) => (queryRefund.apply_refund_reason = validSpace(e))"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="dialogFormVisible = false;">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click=" sureRefund ">保 存</lbButton>
      </div>
    </el-dialog>
    <!-- 查看退款详情 -->
    <lebo-dialog :isShow=" showRefundeDetail " width="70%" title="查看退款详情" :footerSlot=" true "
      @close=" showRefundeDetail = false " closeOnClickModal>
      <seeRefoundDetail :id=" dialogRefoundDetail " @closeDialog=" closeRefundeDetail " v-if=" showRefundeDetail ">
      </seeRefoundDetail>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/orderApi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import seeRefoundDetail from './components/seeRefoundDetail/index.vue'
export default {
  components: { seeRefoundDetail },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: null,
        plate_number: '',
        payment_method: null,
        order_source: null,
        PageSize: 10,
        PageIndex: 1,
        order_no: '',
        datetime_start: '',
        datetime_end: ''
      },
      timeduan: '',
      // 表格数据
      tableData: [],
      TotalCount: 0,
      dialogFormVisible: false,
      queryRefund: {
        refundMoney: '',
        order_id: '',
        apply_refund_reason: ''
      },
      refundFormRules: {
        refundMoney: [
          { required: true, trigger: 'blur' }
        ],
        apply_refund_reason: [
          { required: true, message: '请输入退款原因', trigger: 'blur' }
        ]
      },
      showRefundeDetail: false, // 查看退款记录弹框
      dialogRefoundDetail: '', // 退款详情id
      isMultiRow: true, // 查询下拉弹窗
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '订单号',
            prop: 'order_no'
          }, {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '订单金额',
            prop: 'apply_refund_cost',
            slot: true
          }, {
            label: '支付方式',
            prop: 'payment_method',
            slot: true
          }, {
            label: '订单来源',
            prop: 'order_source',
            slot: true
          }, {
            label: '状态',
            prop: 'order_status',
            slot: true
          }, {
            label: '订单退款时间',
            prop: 'refund_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {
    'timeduan' (newV, oldV) {
      // console.log(newV);
      if (newV) {
        this.queryForm.datetime_start = newV[0]
        this.queryForm.datetime_end = newV[1]
      } else {
        this.queryForm.datetime_start = ''
        this.queryForm.datetime_end = ''
        this.onSubmit()
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      console.log(this.queryForm)
    }
    this.requestOrderInfo()
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    // this.requestPorkingList();
    // this.requestFindList();
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestOrderInfo()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestOrderInfo()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestOrderInfo()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestOrderInfo()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestOrderInfo()
    // },
    find (row) {
      this.showRefundeDetail = true
      this.dialogRefoundDetail = row._id
      // var preStr = this.isRoutW ? 'routw-' : ''
      // this.$router.push({
      //   path: `/${preStr}refund-detail/${row._id}`
      // })
    },
    async requestOrderInfo () {
      var that = this
      const params = {
        parking_id: that.queryForm.parking_id,
        plate_number: that.queryForm.plate_number,
        payment_method: that.queryForm.payment_method,
        order_source: that.queryForm.order_source,
        PageSize: that.queryForm.PageSize,
        PageIndex: that.queryForm.PageIndex,
        order_no: that.queryForm.order_no,
        datetime_start: that.queryForm.datetime_start,
        datetime_end: that.queryForm.datetime_end
      }
      if (that.queryForm.payment_method === '' || that.queryForm.payment_method === null) {
        params.payment_method = 0
      }
      if (that.queryForm.order_source === '' || that.queryForm.order_source === null) {
        params.order_source = 0
      }
      try {
        const res = await request.refundRecordList(params)
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    },
    // 展示退款说明对话框
    refund (row) {
      console.log(row)
      this.queryRefund.refundMoney = row.apply_refund_cost.toFixed(2)
      this.queryRefund.order_id = row.order_id
      this.dialogFormVisible = true
    },
    // 关闭弹框触发
    dialogClose () {
      this.$refs.refundref.resetFields()
    },
    // 确认重新提交退款申请
    sureRefund () {
      this.$refs.refundref.validate((valid) => {
        if (valid) {
          this.requestApplyRefund()
        } else {
          return false
        }
      })
    },
    // 退款申请请求
    async requestApplyRefund () {
      const { order_id = '', apply_refund_reason = '' } = this.queryRefund
      const res = await request.applyRefund({
        order_id: order_id,
        apply_refund_reason: apply_refund_reason
      })
      if (res && res.Code === 200) {
        this.$msg.success('恭喜退款成功')
        this.requestOrderInfo()
      }
      this.dialogFormVisible = false
    },
    closeRefundeDetail () {
      this.showRefundeDetail = false
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }
  }
}
</script>

<style scoped lang="less">.box-card {
  margin-top: 20px;

  .queryBox {
    margin-bottom: 20px;
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 500px;
  }
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 490px !important;
}</style>
