<template>
    <!-- 订单管理 —— 退款记录 -->
    <div class="box-card">
      <!-- 主要内容 -->
      <el-form label-width="180px" :model="tableData" class="detailForm">
        <div class="title">订单详情</div>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12" style="text-align:left;">
            <el-form-item label="车牌号：">{{ tableData.plate_number }}</el-form-item>
            <el-form-item label="停车场：">{{ tableData.parking_name }}</el-form-item>
            <el-form-item label="入场时间：">{{ tableData.go_in_time ? validDateTime(tableData.go_in_time) : ''}}</el-form-item>
            <el-form-item label="应收金额：">￥{{ tableData.calculate_cost ? tableData.calculate_cost.toFixed(2) : '0.00'}}</el-form-item>
            <el-form-item label="优惠金额：">￥{{tableData.discount_amount ? tableData.discount_amount.toFixed(2): '0.00'}}</el-form-item>
            <el-form-item label="订单状态：">{{tableData.order_status?$store.getters.getWayName("OrderStatus", tableData.order_status):''}}</el-form-item>
            <el-form-item label="订单生成时间：">{{ tableData.create_time ? validDateTime(tableData.create_time):"" }}</el-form-item>
            <el-form-item label="订单来源：">{{ getWayName("OrderSource", tableData.order_source) }}</el-form-item>
          </el-col>
          <el-col :span="12" style="text-align:left;">
            <el-form-item label="类型：">{{getWayName('CarOwnerCardType', tableData.car_owner_card_type)}}</el-form-item>
            <el-form-item label="停车时长：">{{tableData.parking_time_length ? getTimeLength(tableData.parking_time_length) :""}}</el-form-item>
            <el-form-item label="出场时间：">{{ tableData.go_out_time ? validDateTime(tableData.go_out_time):'' }}</el-form-item>
            <el-form-item label="实收金额：">￥ {{tableData.final_cost  ?  tableData.final_cost.toFixed(2) : '0.00' }}</el-form-item>
            <el-form-item label="优惠劵：" v-if="tableData.coupon_name">{{tableData.coupon_name}}</el-form-item>
            <el-form-item label="支付方式：">{{ getWayName("PaymentMethod", tableData.payment_method) }}</el-form-item>
            <el-form-item label="支付时间：">{{tableData.payment_time? validDateTime(tableData.payment_time): '' }}</el-form-item>
          </el-col>
        </el-row>
        <div>
          <div class="title">退款说明</div>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :span="12" style="text-align:left;">
              <el-form-item label="退款操作人：">{{tableData.refund_Name}}</el-form-item>
              <el-form-item label="退款金额："> ￥{{ tableData.actual_refund_cost ? tableData.actual_refund_cost.toFixed(2) : '0.00'}}</el-form-item>
              <el-form-item label="状态："> <span :style="tableData.order_status === 5 ? 'color:#ee0000':''">{{getWayName("OrderStatus", tableData.order_status) }}</span></el-form-item>
            </el-col>
            <el-col :span="12" style="text-align:left;">
              <el-form-item label="退款时间：">{{validDateTime(tableData.actual_refund_time)}}</el-form-item>
              <el-form-item label="退款原因：">{{tableData.apply_refund_reason}}</el-form-item>
              <el-form-item label="退款失败原因：" v-if="tableData.fail_reason">{{tableData.fail_reason}}</el-form-item>
              <!-- <el-form-item label="失败原因：">{{tableData.apply_refund_reason}}</el-form-item> -->
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </template>
<script>
import request from '@/api/orderApi'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      // 表格数据
      tableData: {}
    }
  },
  created () { },
  mounted () {
    this.requestOrderDetail({ _id: this.id })
  },
  computed: {
    ...mapGetters(['getWayName']),
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async requestOrderDetail (params) {
      try {
        const res = await request.refundDetail(params)
        // console.log("==--==--==res=", res);
        if (res && res.Code === 200) {
          this.tableData = res.Data
        } else {
          this.tableData = {}
        }
      } catch (error) { }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
    // goBack () {
    //   if (window.history.length > 1) {
    //     this.setQueryStatus(2)
    //     this.$router.go(-1)
    //   } else {
    //     this.$router.push('/')
    //   }
    // }
  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
  }
  .detailForm {
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
    /deep/ .el-form-item__content {
      text-align: left;
    }
  }
  // .el-input__inner {
  //   height: 36px;
  //   line-height: 36px;
  // }
  .button_box {
    display: flex;
  }
  .button_box .el-button {
    margin-left: 15px;
    width: auto;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-button {
    width: auto !important;
  }

  .imagefu img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .itemone {
    display: flex;
    justify-content: flex-end;
  }
  .itemtwo {
    display: flex;
    justify-content: flex-start;
  }
  .card-image {
    max-height: 500px !important;
    object-fit: cover;
  }
  .box-card {
    position: relative;
    padding-bottom: 30px;
    margin-top: 0 !important;
    .checkButton {
        position: absolute;
        right: 50px;
    }

    .checkButton2 {
        position: absolute;
        right: 150px;
    }
}
  </style>
